import React, { useEffect, useState } from 'react';
import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  Form,
  FormLayout,
  Layout,
  RadioButton,
  SkeletonBodyText,
  SkeletonDisplayText,
  Stack,
  TextContainer,
  TextField,
  TextStyle
} from '@shopify/polaris';
import firebase from "../config/Firebase";
import Token from '../common/Token';
import './configuration/configuration.css';

const Configuration = props => {
  const token = Token.getAuthToken();
  
  const [initialized, setInitialized] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [tempData, setTempData] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const fetchInitialData = () => {
    setInitialized(false);

    return firebase.auth().signInWithCustomToken(token).then(() => {
      return firebase.auth().currentUser.getIdTokenResult();
    })
    .then((token) => {
      // Initialize Cloud Firestore through Firebase
      var db = firebase.firestore();
      return db.collection('sfyShops').doc(token.claims.sfy_shop).get();
    })
    .then((doc) => {
      const d = doc.data().config ? doc.data().config : null;

      setFormData({
        clearCartAfterSwitch: d && d.clearCartAfterSwitch !== undefined ? d.clearCartAfterSwitch : true,
        redirectUrl: d && d.redirectUrl ? d.redirectUrl : null
      });

      setInitialized(true);
    })
    .catch((error) => {
      setInitialized(true);
      setHasError(true);
      console.log('error: ' + error.message);
    });
  }

  // eslint-disable-next-line
  useEffect(() => { !initialized && fetchInitialData(); }, []); 

  const enterEditMode = () => {
    setTempData(formData);
    setEditMode(true);
  }

  const exitEditMode = () => {
    setFormData(tempData);
    setEditMode(false);
  }

  const handleFieldValueChange = (newValue, id, key) => {
    let value;

    if (id !== null) {
      value = id.split('_')[0] === 'yes' ? true : false;
    } else {
      value = newValue;
    }

    setFormData({
      ...formData,
      [key]: value
    });
  }

  const handleSubmit = () => {
    setIsSaving(true);

    return firebase.auth().signInWithCustomToken(token).then(() => {
      return firebase.auth().currentUser.getIdTokenResult();
    })
    .then((token) => {
      // Initialize Cloud Firestore through Firebase
      var db = firebase.firestore();
      return db.collection('sfyShops').doc(token.claims.sfy_shop);
    })
    .then((dbRef) => {
      return dbRef.update({
        'config.clearCartAfterSwitch': formData.clearCartAfterSwitch,
        'config.redirectUrl': formData.redirectUrl ? formData.redirectUrl : ''
      }).then(() => {
        setIsSaving(false);
        setEditMode(false);
        setHasError(false);
      }).catch(err => {
        return Promise.reject(new Error('Not able to save to Firestore.'));
      });
    })
    .catch((error) => { 
      setIsSaving(false);
      setEditMode(false);
      setHasError(true);
      console.log('error: ' + error.message);
    });
  }

  const readOnlyModeMarkup = (
    <div className="settings-fields">
      <TextContainer spacing="tight">
        <label className="settings-fields-label">
          Clear cart after switch
        </label>
        <p className="settings-field-read-only-text">
          { formData.clearCartAfterSwitch === true ? 'Yes' : 'No' }
        </p>
      </TextContainer>
      <TextContainer spacing="tight">
        <label className="settings-fields-label">
          Redirect URL
        </label>
        <p className="settings-field-read-only-text">
          { formData.redirectUrl ? formData.redirectUrl : <TextStyle variation="subdued">No URL</TextStyle>}
        </p>
      </TextContainer>
    </div>
  );
  
  const editModeMarkup = (
    <FormLayout>
      <Stack vertical spacing="extraTight">
        <label className="settings-fields-label">
          Clear cart after switch
        </label>
        <RadioButton
          label="No"
          name="clearCart"
          id="no_clearCart"
          checked={formData.clearCartAfterSwitch === false}
          onChange={(newValue, id) => handleFieldValueChange(newValue, id, 'clearCartAfterSwitch')}
        />
        <RadioButton
          label="Yes"
          name="clearCart"
          id="yes_clearCart"
          checked={formData.clearCartAfterSwitch === true}
          onChange={(newValue, id) => handleFieldValueChange(newValue, id, 'clearCartAfterSwitch')}
        />
      </Stack>
      <TextField
        label={(
          <label
            className="settings-fields-label"
            children="Redirect URL"
          />
        )}
        placeholder="URL"
        value={formData.redirectUrl}
        onChange={newValue => handleFieldValueChange(newValue, null, 'redirectUrl')}
        //error={formValidation.redirectUrl}
      />
    </FormLayout>
  );
  
  const formMarkup = (
    <Form onSubmit={handleSubmit}>
      { editMode ? editModeMarkup : readOnlyModeMarkup }
      
      <div className="settings-button-group">
        { editMode ? (
          <ButtonGroup>
            <Button
              primary
              submit
              children="Save"
              loading={isSaving}
            />
            <Button
              children="Cancel"
              onClick={exitEditMode}
              disabled={isSaving}
            />
          </ButtonGroup>
        ) : (
          <Button
            children="Edit"
            onClick={enterEditMode}
          />
        )}
      </div>
    </Form>
  );
  
  const loadingMarkup = (
    <TextContainer>
      <SkeletonDisplayText size="small" />
      <SkeletonBodyText />
    </TextContainer>
  );

  const errorMarkup = (
    <Banner
      title="Error"
      status="warning"
    > 
      <TextContainer>
        <p>
          Something went wrong.
          <br />
          Try reloading the page. If the problem persists, please contact support.
        </p>
      </TextContainer>
    </Banner>
  );
  
  const markup = hasError ? errorMarkup : formMarkup;

  return (
    <Layout.AnnotatedSection
        title="Configuration"
        description="Configure additional app settings."
      >
      <Card sectioned>
        { initialized ? markup : loadingMarkup }
      </Card>
    </Layout.AnnotatedSection>  
  );
}

export default Configuration;