import React, {Component} from 'react';
import {
  Page,
  Toast
} from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import Agents from './agents/Agents';
import ManageAgents from './agents/ManageAgents';
import ManageCustomers from './agents/ManageCustomers';
import AgentDetail from './agents/AgentDetail';
import { Settings } from './settings/Settings';
import { PageNotFound } from './common/PageNotFound';
import { SetupDashboard } from './setup/Dashboard';

// import {getShopOrigin} from '@shopify/app-bridge';
import { History, Redirect as ShopifyRedirect } from '@shopify/app-bridge/actions';

import { Loading } from './common/Loading';
import PaymentRequired from './common/PaymentRequired';
import { Footer } from './common/Footer';
import Unauthorized from './common/Unauthorized';

import firebase from "./config/Firebase";
import Token from './common/Token';

class App extends Component {
  // This line is very important! It tells React to attach the `polaris`
  // object to `this.context` within your component.
  static contextTypes = {
    polaris: PropTypes.object
  };

  state = {
    showToast: false,
    toast: {
      message: '',
      isError: false
    },
    loading: true,
    error: false,
    setupRequired: false,
    shopifyBillingUrl: false,
    shopifyRequiresCharge: false,
    breadCrumbs: [],
    primaryAction: null,
    secondaryActions: [],
    pageTitle: '',
    routing: {
      redirect: false,
      navigateTo: null
    },
    firebase: {
      isLoggedIn: false,
      token: null
    }
  };

  componentDidMount() {
    const token = Token.getAuthToken();

    // set title initially
    const pageTitle = this.getPageTitle(window.location.pathname);
    this.setState({...this.state,
      pageTitle: pageTitle, // update title
    });
  
    if (!token) {
      this.setState({
        loading: false,
        error: true
      });
      return;
    }

    firebase.auth().signInWithCustomToken(token)
    .then(() => {
      return firebase.auth().currentUser.getIdTokenResult();
    })
    .then((token) => {
      // Initialize Cloud Firestore through Firebase
      const db = firebase.firestore();

      // Disable deprecated features
      // db.settings({
      //   timestampsInSnapshots: true
      // });

      return db.collection('sfyShops').doc(token.claims.sfy_shop).get();
    })
    .then((result) => {
      const mutipassToken = result.get('multipassToken');

      // check for billing
      let chargeUpdateRequired = false;
      if (result.get('billingInfo.chargeExempt') !== true && result.get('billingInfo.status') !== 'active') {
        chargeUpdateRequired = true;
      }

      this.setState({...this.state,
        loading: false,
        primaryAction: { 
          content: 'Manage agents',
          onAction: () => this.navigate('/agents'),
        },
        secondaryActions: [
          {
            content: 'Agents',
            onAction: () => this.navigate('/'),
          },
          {
            content: 'Settings',
            onAction: () => this.navigate('/settings'),
          }
        ],
        setupRequired: (mutipassToken ? false : true),
        firebase: {
          loggedIn: true,
          token: token
        },
        shopifyChargeUrl: this.getShopifyChargeUrl(),
        shopifyRequiresCharge: chargeUpdateRequired
      });

    }).catch((error) => {
      this.setState({
        loading: false,
        error: true
      });
    });
}

  componentDidUpdate() {
    // reset the state when a redirect was performed
    if (this.state.routing.redirect === true) {
      this.setState({...this.state,
        routing: {
          redirect: false,
          navigateTo: null
        }
      });
    }
  }

  render() {
    // payment guard
    if (this.state.shopifyChargeUrl) {
      if (this.context.polaris.appBridge) {
        const redirect = ShopifyRedirect.create(this.context.polaris.appBridge);
        redirect.dispatch(ShopifyRedirect.Action.REMOTE, {
          url: this.state.shopifyChargeUrl,
          newContext: false
        });
      }

      // render auth
      return (<Loading description="One moment please" />);
    } else if (this.state.shopifyRequiresCharge === true) { 
      return (<PaymentRequired />);
    } else {
      // set toast message
      const {showToast} = this.state;
      const toastMessage = showToast ? <Toast content={this.state.toast.message} error={this.state.toast.isError} duration={3000} onDismiss={this.toggleToast} /> : null;

      return (
        <Router>
          <Page
            breadcrumbs={this.state.breadCrumbs}
            title={this.state.pageTitle}
            primaryAction={this.state.primaryAction}
            secondaryActions={this.state.secondaryActions}
            fullWidth={true}
          >
            {this.renderContent()}
            {this.routeNavigator()}
            <Footer />
            {toastMessage}
          </Page>
        </Router>
      );
    }
  }

  renderContent() {
    // guard: loading state
    if (this.state.loading === true) { return ( <Loading description="Authenticating" /> ); }

    //guard: check error
    if (this.state.error === true) { return ( <Unauthorized /> ); }

    return (
      <Switch>
        <Route exact path="/" render={props => (this.state.setupRequired ? <SetupDashboard {...props} navigate={this.navigate.bind(this)} /> : <Agents {...props} navigate={this.navigate.bind(this)} showToast={this.showToastMessage.bind(this)} />)}  />
        <Route exact path="/agents" render={props => <ManageAgents {...props} navigate={this.navigate.bind(this)} showToast={this.showToastMessage.bind(this)} />}  />
        <Route path="/agent/:id/detail" render={props => <AgentDetail {...props} navigate={this.navigate.bind(this)} showToast={this.showToastMessage.bind(this)}  />} />
        <Route path="/agent/:id/manage-customers" render={props => <ManageCustomers {...props} navigate={this.navigate.bind(this)} showToast={this.showToastMessage.bind(this)}  />} />
        <Route path="/settings" render={props => <Settings {...props} navigate={this.navigate.bind(this)} showToast={this.showToastMessage.bind(this)} updateSetupRequired={this.updateSetupRequiredState.bind(this)}  />} />
        <Route render={props => <PageNotFound {...props} navigate={this.navigate.bind(this)} showToast={this.showToastMessage.bind(this)}  />} />
      </Switch>
    );
  }

  navigate(location, titleOveride='') {
    // set title based on requested location (title override possible)
    let pageTitle = '';
    if (titleOveride.length > 0) {
      pageTitle = titleOveride;
    } else {
      pageTitle = this.getPageTitle(location);
    }

    // get breadcrumbs if needed
    const breadCrumbs = this.getBreadCrumbs(location);

    this.setState({...this.state,
      breadCrumbs: breadCrumbs,
      pageTitle: pageTitle, // update title
      routing: {
        redirect: true,
        navigateTo: location
      }
    });

    // set url state when running from an iframe
    if (this.context.polaris) {
      const appRedirect = History.create(this.context.polaris.appBridge);
      appRedirect.dispatch(History.Action.PUSH, location + window.location.search);
    }
  }

  toggleToast = () => {
    this.setState(({showToast}) => ({showToast: !showToast}));
  }

  showToastMessage(message, error=false)
  {
    // update the message
    this.setState({...this.state,
      toast: {
        message: message,
        isError: error
      }
    }, this.toggleToast);
  }

  routeNavigator() {
    // guard
    if (this.state.routing.redirect === false) {
      return '';
    }

    return (
      <Redirect push to={{
        pathname: this.state.routing.navigateTo,
        search: (this.state.firebase.token ? '?token=' + this.state.firebase.token : ''),
        // state: 'statevalue'
      }} />
    );
  }

  updateSetupRequiredState = () => {
    this.setState({...true,
      setupRequired: false
    })
  }

  // update page title @todo: perhaps later with redux
  getPageTitle(path) {
    // init
    let title = '';

    // replace digits to a single X so we can match the path
    path = path.replace(/[0-9]+/g, 'X');

    switch(path) {
      default:
      case '/':
        title = 'Agents';
      break;
      case '/agents':
        title = 'Manage agents';
      break;
      case '/customers':
        title = 'Customers';
      break;
      case '/setup':
        title = 'Setup';
      break;
      case '/settings':
        title = 'Settings';
      break;
    }

    return title;
  }

  getBreadCrumbs(path) {
    // init
    let breadcrumbs = [];
    
    // replace digits to a single X so we can match the path
    path = path.replace(/[0-9]+/g, 'X');

    switch(path) {
      default:
        // do nothing or clear breadcrumbs
      break;
      case '/agent/X/customers':
        breadcrumbs.push({
          content: 'Agents',
          onAction: () => { this.navigate('/'); }
        });
      break;
    }

    return breadcrumbs;
  }

  // check if we need to redirect the user to the Shopify Charge page
  // this cannot be done from the cloud function due to cross-frame settings in Shopify
  getShopifyChargeUrl() {
    // init
    let billingUrl = false;

    // verify if this client needs payment
    const urlParams = new URLSearchParams(window.location.search);
    const chargeUrl = urlParams.get('chargeUrl');
    if (chargeUrl) {
      let buff = new Buffer(chargeUrl, 'base64');  
      billingUrl = buff.toString('ascii');
    }

    return billingUrl;
  }
}

export default App;
