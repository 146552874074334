import React from 'react';
import { Button, Card, TextStyle, Subheading, Badge, Stack } from '@shopify/polaris';

export const View = (props) => {
  return(
    <Card>
      <Card.Header title="Multipass secret">
      <Stack>
        <Badge status="info">Active</Badge>
      </Stack>
      </Card.Header>
      <Card.Section>
        <Subheading variation="subdued">TOKEN</Subheading>
        <TextStyle variation="code">{props.multipassToken}</TextStyle><br /><br />
        <Button onClick={props.toggleForm}>Replace multipass secret</Button>
      </Card.Section>
    </Card>
  );
};