import React from 'react';
import { Layout, EmptyState } from '@shopify/polaris';

export const PageNotFound = (props) => {
  return (
    <Layout>
      <Layout.Section>
        <EmptyState
          heading="The page you're looking for couldn't be found"
          action={{content: 'Reopen App', target: 'REMOTE', url: '/' }}
          image="/assets/404.svg"
          // largeImage="/assets/illustration.svg"
          // imageContained={true}
          // image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        >
          <p>
            Check the web address and try again. Or reopen the app from the apps section in Shopify.
          </p>
        </EmptyState>
      </Layout.Section>
    </Layout>
  );
};
