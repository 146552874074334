import React from 'react';
import { Layout, EmptyState } from '@shopify/polaris';

export const SetupDashboard = (props) => {
  return (
    <Layout>
      <Layout.Section>
        <EmptyState
          heading="Shop for your customers"
          action={{content: 'Set up Identity Switcher', onAction: () => { props.navigate('/settings') } }}
          image="/assets/illustration.svg"
          // largeImage="/assets/illustration.svg"
          // imageContained={true}
          // image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        >
          <p>
            Make purchases on you customer's behalf. <br />
            You will have to make some settings in order to start.
          </p>
        </EmptyState>
      </Layout.Section>
    </Layout>
  );
};
