import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyCOi2QwSFpJJ9eGcuiiwYnGGUULDd8lQqQ",
    authDomain: "code-identity-switcher.firebaseapp.com",
    databaseURL: "https://code-identity-switcher.firebaseio.com",
    projectId: "code-identity-switcher",
    storageBucket: "code-identity-switcher.appspot.com",
    messagingSenderId: "53665435461"
};

// Initialize Firebase
firebase.initializeApp(config);

export default firebase;
