import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import { Layout } from '@shopify/polaris';
import firebase from "../config/Firebase";

import { Install } from './installation/Install';
import { Loading } from './installation/Loading';

import Token from '../common/Token';

import { Shopify } from '../config/Shopify';

class Installation extends Component {
  // This line is very important! It tells React to attach the `polaris`
  // object to `this.context` within your component.
  static contextTypes = {
    polaris: PropTypes.object
  };

  state = {
    loading: true,
    buttonLoading: false,
    snippetsInstalled: false
  };

  componentWillMount() {
    const token = Token.getAuthToken();

    firebase.auth().signInWithCustomToken(token)
      .then(() => {
        return firebase.auth().currentUser.getIdTokenResult();
      })
      .then((token) => {
        // Initialize Cloud Firestore through Firebase
        var db = firebase.firestore();
        
        // Disable deprecated features
        // db.settings({
        //   timestampsInSnapshots: true
        // });

        return db.collection('sfyShops').doc(token.claims.sfy_shop).get();
      })
      .then((doc) => {
        const installedSnippets = doc.get('installedLiquidAssets');
        const snippetsInstalled = !this.isObjectEmpty(installedSnippets);

        this.setState({...this.state,
          loading: false,
          snippetsInstalled: snippetsInstalled
        });
      })
      .catch((error) => {
        console.log('error: ' + error.message);
      });
  }

  installSnippets = () => {
    // set button on loading
    this.setState({...this.state,
      buttonLoading: true
    });

    // obtain the token that is used for this session
    const token = Token.getAuthToken();

    firebase.auth().signInWithCustomToken(token)
    .then(() => {
      return firebase.auth().currentUser.getIdToken();
    })
    .then((token) => {
      const installUri = `${Shopify.assetInstallUrl}`;

      return fetch(installUri, {
        method: 'post',
        headers: {
          'content-type': 'application/json'
        },
        body: `{"token": "${token}"}`
      })
    })
    .then((result) => {
      this.setState({...this.state,
        buttonLoading: false,
        snippetsInstalled: true
      });

      // send feedback
      if (this.context.polaris) {
        this.props.showToast(`Succesfully installed snippets`);
      }
    })
    .catch((error) => {
      this.setState({...this.state,
        buttonLoading: false
      });

      // send feedback
      if (this.context.polaris) {
        this.props.showToast(`An error occured, please try again, perform installation manually or contact support`, true);
      }
    });
  }

  unInstallSnippets = () => {
    // set button on loading
    this.setState({...this.state,
      buttonLoading: true
    });

    // obtain the token that is used for this session
    const token = Token.getAuthToken();

    firebase.auth().signInWithCustomToken(token)
    .then(() => {
      return firebase.auth().currentUser.getIdToken();
    })
    .then((token) => {
      const installUri = `${Shopify.assetUninstallUrl}`;

      return fetch(installUri, {
        method: 'post',
        headers: {
          'content-type': 'application/json'
        },
        body: `{"token": "${token}"}`
      })
    })
    .then((result) => {
      this.setState({...this.state,
        buttonLoading: false,
        snippetsInstalled: false
      });

      // send feedback
      if (this.context.polaris) {
        this.props.showToast(`Succesfully uninstalled snippets`);
      }
    })
    .catch((error) => {
      this.setState({...this.state,
        buttonLoading: false
      });

      // send feedback
      if (this.context.polaris) {
        this.props.showToast(`An error occured, please try again, perform uninstall manually or contact support`, true);
      }
    });
  }

  isObjectEmpty(obj) {
    // guard
    if (obj === undefined) {
      return true;
    }

    for(let key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  render() {
    let instructions = <Loading />;

    // change content when not in loading state
    if (this.state.loading === false) {
      instructions = <Install 
        snippetsInstalled={this.state.snippetsInstalled} 
        installSnippets={this.installSnippets.bind(this)} 
        unInstallSnippets={this.unInstallSnippets.bind(this)} 
        buttonLoading={this.state.buttonLoading} 
        showToast={this.props.showToast.bind(this)}
      />;
    }

    return (
      <Layout.AnnotatedSection
        title="Installation instructions"
        description="Install liquid snippets in order to use the app"
      > 
        {instructions}
      </Layout.AnnotatedSection>  
    );
  }
}

export default Installation;