import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import { ButtonGroup, Button, Card, Form, FormLayout, TextField } from '@shopify/polaris';
import firebase from "../../config/Firebase";

import Token from '../../common/Token';

class EditForm extends Component {
  // This line is very important! It tells React to attach the `polaris`
  // object to `this.context` within your component.
  static contextTypes = {
    polaris: PropTypes.object
  };

  state = {
    loading: true,
    showForm: true,    
    btnLoading: false,
    errorMessage: '',
    multipassToken: '',
    multipassTokenField: ''
  };

  handleChange = (multipassTokenField) => {
    this.setState({multipassTokenField});
  };
 
  validateForm() {
    // init
    let validForm = true;
  
    // check if filled
    if (this.state.multipassTokenField.length < 10) {
      this.setState({...this.state,
        errorMessage: 'Valid Multipass token is required'
      });

      validForm = false;
    }

    return validForm;
  }

  handleSubmit = () => {
    //guard
    if (!this.validateForm()) {
      return;
    }

    // set button loading
    this.setState({...this.state,
      btnLoading: true,
      errorMessage: ''
    });

    const token = Token.getAuthToken();

    firebase.auth().signInWithCustomToken(token)
      .then(() => {
        return firebase.auth().currentUser.getIdTokenResult();
      })
      .then((token) => {
        // Initialize Cloud Firestore through Firebase
        var db = firebase.firestore();

        // Disable deprecated features
        // db.settings({
        //   timestampsInSnapshots: true
        // });

        return db.collection('sfyShops').doc(token.claims.sfy_shop);
      })
      .then((doc) => {
        return doc.set({multipassToken: this.state.multipassTokenField}, { merge: true });
      })
      .then(() => {
        // update
        this.setState({...this.state,
          btnLoading: false,
          multipassToken: this.state.multipassTokenField,
          multipassTokenField: ''
        });

        // update parent states and return
        this.props.updateToken(this.state.multipassToken);
        this.props.toggleForm();
        this.props.updateSetupRequired();
        
        // send feedback
        if (this.context.polaris) {
          this.props.showToast(`Multipass token is updated`);
        }
      })
      .catch((error) => {
        this.props.showToast(`Something went wrong while saving the settings. Please reload and try again`, true);
        console.log(error.message);
      });
  };

  render() {
    return (
    <Card sectioned>
      <Form onSubmit={this.handleSubmit}>
        <FormLayout>
          <TextField 
            label="Multipass secret" 
            labelAction={{content: 'Obtain multipass secret', url: `https://${this.props.shopifyDomain}/admin/settings/checkout`, external:true }}
            helpText="You can obtain the Multipass secret in the Checkout settings of your shop. You will have to enable multipass."
            value={this.state.multipassTokenField}
            error={this.state.errorMessage}
            autoFocus={true}
            onChange={this.handleChange} />
          <ButtonGroup>
            <Button submit loading={this.state.btnLoading} primary={true}>Save</Button>
            <Button onClick={this.props.toggleForm} disabled={this.props.multipassToken ? false : true}>Cancel</Button>
          </ButtonGroup>
        </FormLayout>
      </Form>
    </Card>
    );
  }
}

export default EditForm;