import React from 'react';
import { Card, SkeletonDisplayText, SkeletonBodyText, TextContainer } from '@shopify/polaris';

export const Loading = () => {
  return (
    <Card sectioned>
      <TextContainer>
        <SkeletonDisplayText size="small" />
        <SkeletonBodyText />
      </TextContainer>
    </Card>
  );
}
