import React from 'react';
import { Layout, EmptyState } from '@shopify/polaris';

export const WelcomeScreen = (props) => {
  return (
    <Layout>
      <Layout.Section>
        <EmptyState
          heading="Welcome to the Identity Switcher!"
          action={{content: 'Manage Agents', onAction: () => { props.navigate('agents') } }} // @todo, redirect on top level and reopen app
          image="/assets/illustration.svg"
        >
          <p>Agents can switch between identities. You can create an agent by assigning the agent role to a customer.</p>
        </EmptyState>
      </Layout.Section>
    </Layout>
  );
}
