import React, { Component } from 'react';
import { Layout, EmptyState } from '@shopify/polaris';
import * as PropTypes from 'prop-types';

// import { Shopify } from '../config/Shopify';
// import { Redirect } from '@shopify/app-bridge/actions';

class Unauthorized extends Component {
  static contextTypes = {
    polaris: PropTypes.object
  };

  reopenApp() {
    // get contexts
    // if (this.context.polaris) {
    //   const appRedirect = Redirect.create(this.context.polaris.appBridge);
    //   appRedirect.dispatch(Redirect.Action.REMOTE, {
    //     url: 'https://code-plus-sandbox.myshopify.com/admin' + Shopify.appOauthUrl
    //   });
    // }
  }

  render() {
    return (
      <Layout>
        <Layout.Section>
          <EmptyState
            heading="There is a problem loading this page"
            action={{content: 'Reload this page', onAction: () => { this.reopenApp() } }} // @todo, redirect on top level and reopen app
            image="/assets/400.svg"
          >
            <p>There is an authorisation problem that prevents the app from being loaded. Try reloading this page, or navigate to the page from the Shopify Apps page.</p>
          </EmptyState>
        </Layout.Section>
      </Layout>
    );
  }
};

export default Unauthorized;