import React from 'react';
import { Layout, Card, ButtonGroup,Button, TextStyle } from '@shopify/polaris';

// import './AgentList.css';

export const AssignAgent = (props) => {
  // set the customer name
  const sfyFullname = `${props.customer.firstName} ${props.customer.lastName}`;

  // set titles and actioncontent
  const cartTitle = `${sfyFullname} is not an agent yet`;
  const actionContent = `Make ${sfyFullname} agent`;

  return (
    <Layout>
      <Layout.Section>
        <Card
            title={cartTitle}
            sectioned
          >
            <div>
              In order to assign customers you will have to make <TextStyle variation="strong">{sfyFullname}</TextStyle> an agent. This will add a tag to the customer which indicates that his customer may assume other customers.
                When the customer is an agent you can assign other customers.
                <div style={{marginTop: '20px'}}>
                  <ButtonGroup>
                    <Button primary loading={props.buttonLoading} onClick={() => { props.assignAsAgent(props.customer.legacyResourceId) }}>{actionContent}</Button>
                    <Button plain={true}>Learn more about making a customer an agent</Button>
                  </ButtonGroup>
                </div>
            </div>

          </Card>
      </Layout.Section>
    </Layout>
  );
};