import { Shopify } from '../config/Shopify';
import ShopifyApiClient from '../common/ShopifyApiClient';

class ShopifyActions {
  /**
   * Make a customer to an agent
   *
   * @param {integer} customerId Shopify ID of the customer
   * @returns {Promise}
   */
  static assignCustomerAsAgent(customerId) {
      // set query
      const query = `
        mutation addTag {
          tagsAdd (
            id: "gid://shopify/Customer/${customerId}", 
            tags: ["${Shopify.agentTag}"]
          ) 
          {
            userErrors {
              field
              message
            }
            node {
              id
            }
          }
        }
      `;
      
      return ShopifyActions.executeMutationQuery(query);
  }

  /**
   * Revoke agent from a customer
   *
   * @param {integer} customerId Shopify ID of the customer
   * @returns {Promise}
   */
  static revokeCustomerAsAgent(customerId) {
    // set query
    const query = `
      mutation removeTag {
        tagsRemove (
          id: "gid://shopify/Customer/${customerId}", 
          tags: ["${Shopify.agentTag}", "${Shopify.superAgentTag}"]
        ) 
        {
          userErrors {
            field
            message
          }
          node {
            id
          }
        }
      }
    `;
    
    return ShopifyActions.executeMutationQuery(query);
}

 /**
   * Assign a customer to an agent
   *
   * @param {integer} customerAgentId Shopify ID of the Customer Agent
   * @param {integer} customerAgentId Shopify ID of the assuming customer
   * @returns {Promise}
   */
  static assignCustomerToAgent(customerAgentId, customerAssumeId) {
    // set tag
    const assumeTag = Shopify.customerTag.replace('$X',customerAgentId);

    // set query
    const query = `
      mutation addTag {
        tagsAdd (
          id: "gid://shopify/Customer/${customerAssumeId}", 
          tags: ["${assumeTag}"]
        ) 
        {
          userErrors {
            field
            message
          }
          node {
            id
          }
        }
      }
    `;

    // console.log(query);
    
    return ShopifyActions.executeMutationQuery(query);
}

 /**
   * Revoke a customer from an agent
   *
   * @param {integer} customerAgentId Shopify ID of the Customer Agent
   * @param {integer} customerAgentId Shopify ID of the assuming customer
   * @returns {Promise}
   */
  static revokeCustomerFromAgent(customerAgentId, customerAssumeId) {
    // set tag
    const assumeTag = Shopify.customerTag.replace('$X',customerAgentId);

    // set query
    const query = `
      mutation removeTag {
        tagsRemove (
          id: "gid://shopify/Customer/${customerAssumeId}", 
          tags: ["${assumeTag}"]
        ) 
        {
          userErrors {
            field
            message
          }
          node {
            id
          }
        }
      }
    `;
    
    return ShopifyActions.executeMutationQuery(query);
  }

  /**
   * Make the customer to a normal agent
   *
   * @param {integer} customerId Shopify ID of the customer
   * @returns {Promise}
   */
  static makeCustomerSuperAgent(customerId) {
    // set query
    const query = `
      mutation addTag {
        tagsAdd (
          id: "gid://shopify/Customer/${customerId}", 
          tags: ["${Shopify.superAgentTag}"]
        ) 
        {
          userErrors {
            field
            message
          }
          node {
            id
          }
        }
      }
    `;
    
    return ShopifyActions.executeMutationQuery(query);
  }

  /**
   * Make the customer to a normal agent
   *
   * @param {integer} customerId Shopify ID of the customer
   * @returns {Promise}
   */
  static makeCustomerNormalAgent(customerId) {
    // set query
    const query = `
      mutation removeTag {
        tagsRemove (
          id: "gid://shopify/Customer/${customerId}", 
          tags: ["${Shopify.superAgentTag}"]
        ) 
        {
          userErrors {
            field
            message
          }
          node {
            id
          }
        }
      }
    `;
    
    return ShopifyActions.executeMutationQuery(query);
  }


  static executeMutationQuery(query) {
    return new Promise((resolve, reject) => {
      // run query
      ShopifyApiClient.mutate(query)
        .then((result) => {
          return resolve(result);
        })
        .catch((error) => {
          console.log(error.message);
          return reject(error.message);
        });
    });
  }
}

export default ShopifyActions;