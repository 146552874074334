import React from 'react';
import { Badge, Button, Card, Link, TextStyle } from '@shopify/polaris';
import { Shopify } from '../../config/Shopify';
// import './AgentList.css';

export const AgentInfo = (props) => {
  // create tag
  const customerTag = Shopify.customerTag.replace('$X',props.customer.legacyResourceId);

  return (
    <Card title='Agent'>
      <Card.Section>
        <TextStyle variation="subdued">{props.customer.firstName} {props.customer.lastName}</TextStyle>
      </Card.Section>

      <Card.Section title="Contact information">
        <TextStyle variation="subdued">
          <Link url={"mailto:" + props.customer.email}>{props.customer.email}</Link>
        </TextStyle>
      </Card.Section>
      <Card.Section title="Agent Tag">
        <Badge status="info">{customerTag}</Badge>
      </Card.Section>
      <Card.Section>
        <Button destructive size="slim" onClick={props.toggleModal}>Revoke agent rights</Button>
      </Card.Section>
    </Card>
  );
};
