/**
 * Static method to obtain the token from the URI
 */
class Token {
  static getAuthToken() {
    // get the token
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    return token;
  }
}

export default Token;