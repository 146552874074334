import fetch from 'node-fetch';
import firebase from "../config/Firebase";
import Token from './Token';

import { Shopify } from '../config/Shopify';

/**
 * Shopify API Connector
 */
class ShopifyApiClient
{
  /**
   * Perform a query against the Shopify GraphQL API
   *
   * @param {String} graphqlQuery The query for Shopify
   * @returns {Promise}
   */
  static query(graphqlQuery) {
    return new Promise((resolve, reject) => {
      // obtain the token that is used for this session
      const token = Token.getAuthToken();

      firebase.auth().signInWithCustomToken(token)
      .then(() => {
        return firebase.auth().currentUser.getIdToken();
      })
      .then((token) => {
        const queryUri = Shopify.queryUrl;
        const grahpQlQuery = graphqlQuery;

        return fetch(queryUri, {
          method: 'post',
          headers: {
            'authorization': `Bearer ${token}`
          },
          body: grahpQlQuery
        })
      })
      .then((result) => {
        return resolve(result.json());
      })
      .catch((error) => {
        return reject(error.message);
      });
    });
  }

  /**
   * Perform a mutation against the Shopify GraphQL API
   *
   * @param {String} graphqlQuery The query for Shopify
   * @returns {Promise}
   */
  static mutate(graphqlQuery) {
    return new Promise((resolve, reject) => {
      // obtain the token that is used for this session
      const token = Token.getAuthToken();

      firebase.auth().signInWithCustomToken(token)
      .then(() => {
        return firebase.auth().currentUser.getIdToken();
      })
      .then((token) => {
        const queryUri = Shopify.queryUrl;
        const grahpQlQuery = graphqlQuery;

        return fetch(queryUri, {
          method: 'post',
          headers: {
            'authorization': `Bearer ${token}`,
            'X-Shopify-Action': 'mutate'
          },
          body: grahpQlQuery
        })
      })
      .then((result) => {
        return resolve(result.json());
      })
      .catch((error) => {
        return reject(error.message);
      });
    });
  }
}

export default ShopifyApiClient;
