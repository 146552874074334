import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import { Layout } from '@shopify/polaris';
import firebase from "../config/Firebase";

import { Loading } from './multipass/Loading';
import EditForm from './multipass/EditForm';
import { View } from './multipass/View';

import Token from '../common/Token';

class MultiPass extends Component {
  // This line is very important! It tells React to attach the `polaris`
  // object to `this.context` within your component.
  static contextTypes = {
    polaris: PropTypes.object
  };

  state = {
    loading: true,
    showForm: true, 
    multipassToken: '',
    shopifyDomain: ''
    // btnLoading: false,
    // errorMessage: '',
    // multipassToken: '',
    // multipassTokenField: ''
  };

  componentWillMount() {
    const token = Token.getAuthToken();

    firebase.auth().signInWithCustomToken(token)
      .then(() => {
        return firebase.auth().currentUser.getIdTokenResult();
      })
      .then((token) => {
        // Initialize Cloud Firestore through Firebase
        var db = firebase.firestore();

        // Disable deprecated features
        // db.settings({
        //   timestampsInSnapshots: true
        // });

        return db.collection('sfyShops').doc(token.claims.sfy_shop).get();
      })
      .then((doc) => {
        const multipassToken = doc.get('multipassToken');
        this.setState({...this.state,
          loading: false,
          showForm: multipassToken ? false : true,
          shopifyDomain: doc.id,
          multipassToken: multipassToken
        });
      })
      .catch((error) => {
        console.log('error: ' + error.message);
      });
  }
  
  toggleForm = () => {
    this.setState(({showForm}) => {
      return {showForm: !showForm};
    });
  };

  updateToken = (newToken) => {
    this.setState({
      multipassToken: newToken
    });
  }

  render() {
    let content = '';
  
    // loading guard
    if (this.state.loading === true) {
      content = <Loading />;
    } else {
      // check token state
      if (this.state.showForm) {
        content = <EditForm toggleForm={this.toggleForm.bind(this)} showToast={this.props.showToast.bind(this)} updateSetupRequired={this.props.updateSetupRequired.bind(this)} updateToken={this.updateToken.bind(this)} multipassToken={this.state.multipassToken} shopifyDomain={this.state.shopifyDomain} />;
      } else {
        content = <View toggleForm={this.toggleForm.bind(this)} multipassToken={this.state.multipassToken} />;
      }
    }

    return (
      <Layout.AnnotatedSection
        title="Set up multipass secret"
        description="The Identity Switcher app needs the multipass secret in order to enable an agent to login as a customer"
      > 
        {content}
      </Layout.AnnotatedSection>  
    );
  }
}

export default MultiPass;