import React, {Component} from 'react';
import { Button, Card, Layout, Modal, Stack } from '@shopify/polaris';
import * as PropTypes from 'prop-types';

import { AssignAgent } from './detail/AssignAgent';
import { AgentInfo } from './detail/AgentInfo';
import CustomerList from './detail/CustomerList';

import { Loading } from './Loading';

import Token from '../common/Token';
import { Shopify } from '../config/Shopify';

import ShopifyApiClient from '../common/ShopifyApiClient';
import ShopifyActions from '../actions/ShopifyActions';

class AgentDetail extends Component {
  static contextTypes = {
    polaris: PropTypes.object
  };
  
  state = {
    loading: true,
    error: false,
    customer: null,
    assignButtonLoading: false,
    activeRevokeModal: false,
    activeAgentTypeModal: false
  };

  // initial load
  componentWillMount() {
    // get the ID of the customer (get from the params)
    const sfyCustomerId = this.props.match.params.id;

    // get the customer By ID
    // console.log(`Obtain Shopify Customer ID: ${sfyCustomerId}`);
    this.getCustomerById(sfyCustomerId);
  }

  // handle the search change
  getCustomerById(sfyCustomerId) {
    const token = Token.getAuthToken();

    if (!token) {
      this.setState({
        loading: false,
        error: true
      });
      return;
    }

    // set query
    const query = `{
      customer(id: "gid://shopify/Customer/${sfyCustomerId}") {
        legacyResourceId
        firstName
        lastName
        email
        tags
      }
    }`;
    // console.log(query);

    ShopifyApiClient.query(query)
      .then((result) => {
        const shopifyData = result.data.customer;

        // // update the state so the rendering is updated as well
        if (shopifyData) {
          // set state
          this.setState({...this.state,
            loading: false,
            customer: shopifyData,
            activeRevokeModal: false
          });

          // run the navigate option to force an update of the title (in case of a hard refresh)
          // since were already on the correct page it will only update the title
          this.props.navigate(this.props.location.pathname, `${shopifyData.firstName} ${shopifyData.lastName}`);
        }
      })
      .catch(function(error) {
          console.log("Error getting documents: ", error);
      });
  }

  toggleRevokeModal = () => {
    this.setState(({activeRevokeModal}) => ({activeRevokeModal: !activeRevokeModal}));

    // give feedback to the user
    // this.props.showToast('Customer agent state was changed');
  }

  toggleAgentTypeModal = () => {
    this.setState(({activeAgentTypeModal}) => ({activeAgentTypeModal: !activeAgentTypeModal}));
    // give feedback to the user
    // this.props.showToast('Customer agent state was changed');
  }

  assignAgent = (customerId) => {
    // set loading state
    this.setState({...this.state,
      assignButtonLoading: true
    });

    ShopifyActions.assignCustomerAsAgent(customerId)
      .then(() => {
        // set loading state
        this.setState({...this.state,
          loading: true,
          assignButtonLoading: false
        });

        // reload the customer
        this.getCustomerById(this.state.customer.legacyResourceId);

        // show message
        this.props.showToast(`Customer ${this.state.customer.firstName} ${this.state.customer.lastName} is an agent now. You can add customers.`);
      })
      .catch((error) => {
        this.props.showToast(`Something went wrong while performing an agent assign`, true);
        console.log(error.message);
      });
  }

  revokeAgent = (customerId) => {
    // set loading state
    this.setState({...this.state,
      loading: true,
      assignButtonLoading: true
    });

    ShopifyActions.revokeCustomerAsAgent(customerId)
      .then(() => {
        // set loading state
        this.setState({...this.state,
          loading: true,
          activeRevokeModal: false,
          assignButtonLoading: false
        });

        // reload the customer
        this.getCustomerById(this.state.customer.legacyResourceId);

        // show message
        this.props.showToast(`Agent rights for ${this.state.customer.firstName} ${this.state.customer.lastName} has been revoked.`);
      })
      .catch((error) => {
        // set loading state
        this.setState({...this.state,
          loading: false,
          activeRevokeModal: false,
          assignButtonLoading: false
        });

        this.props.showToast(`Something went wrong while performing an agent revoke`, true);
        console.log(error.message);
      });
  }

  switchAgentType = (customerId, isSuperAgent) => {
    // set loading state
    this.setState({...this.state,
      loading: true,
      assignButtonLoading: true
    });

    // set action
    const agentAction = () => {
      if (isSuperAgent === true) {
        return ShopifyActions.makeCustomerNormalAgent(customerId);
      } else {
        return ShopifyActions.makeCustomerSuperAgent(customerId);
      }
    }

    agentAction()
      .then(() => {
        // set loading state
        this.setState({...this.state,
          loading: true,
          activeAgentTypeModal: false,
          assignButtonLoading: false
        });

        // reload the customer
        this.getCustomerById(this.state.customer.legacyResourceId);

        // show message
        const responseMessage = isSuperAgent ? `Agent ${this.state.customer.firstName} ${this.state.customer.lastName} is updated to normal agent` : `Agent ${this.state.customer.firstName} ${this.state.customer.lastName} is updated to super agent`;
        this.props.showToast(responseMessage);
      })
      .catch((error) => {
        // set loading state
        this.setState({...this.state,
          loading: false,
          activeAgentTypeModal: false,
          assignButtonLoading: false
        });

        this.props.showToast(`Something went wrong while switching the type of agent`, true);
        console.log(error.message);
      });
  }


  render() {
    // loading guard
    if (this.state.loading === true) {
      return (
        <Loading />
      );
    } else {
      // check if this customer is an agent
      if (this.state.customer.tags.includes(Shopify.agentTag)) {
        const isSuperAgent = this.state.customer.tags.includes(Shopify.superAgentTag) ? true : false

        const introText = isSuperAgent ? <p>
          At this moment this agent can assume the identity of <strong>all customers</strong>.<br />
          When making this agent a Normal agent you can assign customers to assume.
        </p> : <p>
          At this moment this agent can assume the identity of <strong>assigned customers</strong>.<br />
          When making this agent a Super agent all customers can be assumed.
        </p>;
        const buttonText = isSuperAgent ? 'Make normal agent' : 'Make super agent';

        // set list
        const customerAssignBox = <CustomerList 
          navigate={this.props.navigate.bind(this)} 
          agent={this.state.customer} 
          showToast={this.props.showToast.bind(this)}
          assignedCustomersOnly={true}
        />

        const noCustomerSelection = <Card>
          <Card.Header  title="Manage customers">
          </Card.Header>
          <Card.Section>
            This agent can assume all customers because he or she is a super agent. There is no need to select customers.
          </Card.Section>
        </Card>

        const customerBox = isSuperAgent ? noCustomerSelection : customerAssignBox;

        // set confirm modal box
        // const superAgentModalText = isSuperAgent ? 'Are you sure you want to make this agent a Normal agent. This means you manually will have to select the assumable customers.' : 'Are you sure you want to make this agent a Super agent? This means this agent can assume all customers.';
        // const superAgentConfirmModal = 
        //   <Modal
        //     open={this.state.activeAgentTypeModal}
        //     onClose={() => { this.setState({...this.state, activeAgentTypeModal: false}); }}
        //     message={superAgentModalText}
        //     title="Are you sure?"
        //     primaryAction={{
        //       content: buttonText,
        //       disabled: this.state.assignButtonLoading,
        //       onAction: () => { 
        //         this.setState({...this.state, activeAgentTypeModal: false});

        //         this.switchAgentType(this.state.customer.legacyResourceId, isSuperAgent);
        //       }
        //     }}
        //     secondaryActions={[
        //       {
        //         content: 'Cancel',
        //         onAction: this.toggleAgentTypeModal
        //       },
        //     ]}
        //   />;
        //   {superAgentConfirmModal}

        return (
          <Layout>
            <Layout.Section>
              <Card title={`Agent: ${this.state.customer.firstName} ${this.state.customer.lastName}`} sectioned>
              <Stack>
                <Stack.Item fill>
                  {introText}
                </Stack.Item>
                <Stack.Item>
                  <Button primary={!isSuperAgent} onClick={() => this.switchAgentType(this.state.customer.legacyResourceId, isSuperAgent)}>{buttonText}</Button>
                </Stack.Item>
              </Stack>
              </Card>
              {customerBox}
            </Layout.Section>
            <Layout.Section secondary>
              <AgentInfo 
                customer={this.state.customer}
                toggleModal={this.toggleRevokeModal}
                />
              <Modal
                open={this.state.activeRevokeModal}
                onClose={() => { this.setState({...this.state, activeRevokeModal: false}); }}
                message="You will revoke the admin rights for this user? The customer associations will be maintained."
                title="Are you sure?"
                primaryAction={{
                  content: 'Revoke agent rights',
                  disabled: this.state.assignButtonLoading,
                  destructive: true,
                  onAction: () => { 
                    this.setState({...this.state, activeRevokeModal: false});
                    this.revokeAgent(this.state.customer.legacyResourceId);
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: this.toggleRevokeModal
                  },
                ]}
              />

            </Layout.Section>
          </Layout>
        )
      } else {
        return (
          <AssignAgent 
              customer={this.state.customer}
              buttonLoading={this.state.assignButtonLoading}
              assignAsAgent={this.assignAgent.bind(this)}
          />
        );
      }
    }
  }
};

export default AgentDetail;