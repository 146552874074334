import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import { Page, Layout, CalloutCard  } from '@shopify/polaris';
import { Redirect } from '@shopify/app-bridge/actions';

class PaymentRequired extends Component {
  // This line is very important! It tells React to attach the `polaris`
  // object to `this.context` within your component.
  static contextTypes = {
    polaris: PropTypes.object
  };

  navigateToPayment = () => {
    // set url state when running from an iframe
    if (this.context.polaris) {
      const redirect = Redirect.create(this.context.polaris.appBridge);
      redirect.dispatch(Redirect.Action.ADMIN_PATH, {
        url: '/apps',
        newContext: false
      });
    }
  }

  render() {
    return (
      <Page fullWidth={true}>
        <Layout>
          <Layout.Section>
            <CalloutCard
              title="Please setup your Billing details"
              illustration="/assets/waystosell.png"
              primaryAction={{
                content: 'Finish billing setup',
                // external: true,
                primary: true,
                onAction: this.navigateToPayment
              }}
            >
              <p>
                It seems that billing of the app has not been setup yet (or was not recordered properly in our systems). <br />
                Please re-open the app through the App overview and re-open the app from your Shopify shop.
              </p>
            </CalloutCard>
    
          </Layout.Section>
        </Layout>
      </Page>
      );
  }
};

export default PaymentRequired;
