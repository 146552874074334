import React from 'react';
import { Avatar, Badge, ResourceList, TextStyle, Pagination } from '@shopify/polaris';
import { Shopify } from '../config/Shopify';

import './AgentList.css';

export const AgentList = (props) => {
  // set pagination
  let pagination = '';


  if (props.config.hasPrevious || props.config.hasNext) {
    pagination = 
    <Pagination
      hasPrevious={props.config.hasPrevious}
      onPrevious={() => {
        props.listNavigation('previous', props.config.firstCursor);
      }}
      hasNext={props.config.hasNext}
      onNext={() => {
        props.listNavigation('next', props.config.lastCursor);
      }}
      />;
  }

  return (
    <div>
      <ResourceList
        resourceName={{singular: props.resourceNameSingular, plural: props.resourceNamePlural}}
        items={props.config.data}
        loading={props.loading}
        renderItem={(item) => {
          const {legacyResourceId, email, firstName, lastName, tags} = item.node;
          const media = <Avatar customer size="medium" name={firstName + ' ' + lastName} />;

          let agentPill = '';  
          if (tags.includes(Shopify.agentTag)) {
            if (tags.includes(Shopify.superAgentTag)) {
              agentPill = <div className="agentAssigned"><Badge status="success">Super agent</Badge></div>;
            } else {
              agentPill = <div className="agentAssigned"><Badge status="info">Agent</Badge></div>;
            }

          }

          return (
            <ResourceList.Item
              id={legacyResourceId}
              media={media}
              // url={url}
              onClick={() => { 
                let customTitle = `${firstName} ${lastName}`;
                props.navigate(`/agent/${legacyResourceId}/detail`, customTitle); 
              } }
              accessibilityLabel={`View customer overview for ${firstName} ${lastName}`}
            >
              <div className="agent">
                <TextStyle variation="strong">{firstName} {lastName}</TextStyle> {agentPill}<br />
                <TextStyle variation="subdued">{email}</TextStyle>
              </div>
            </ResourceList.Item>
          );
        }
      }
      filterControl={
        <ResourceList.FilterControl
          // filters={availableFilters}
          // appliedFilters={appliedFilters}
          // onFiltersChange={this.handleFiltersChange}
          searchValue={props.searchValue}
          onSearchChange={props.handleSearchChange}
          // additionalAction={{
          //   content: 'Save',
          //   // onAction: this.handleSaveFilters,
          // }}
        />
      }
      />
      <center>
        {pagination}
      </center>
    </div>
  );
}
