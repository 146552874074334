import React, {Component} from 'react';
import { Layout, Card } from '@shopify/polaris';
import * as PropTypes from 'prop-types';

import { Shopify } from '../config/Shopify';
import { AgentList } from './AgentList';
import firebase from "../config/Firebase";
import Token from '../common/Token';

import ShopifyApiClient from '../common/ShopifyApiClient';

class ManageAgents extends Component {
  static contextTypes = {
    polaris: PropTypes.object
  };

  state = {
    agentsOnly: false,
    error: false,
    listConfig: {
      data: [],
      hasPrevious: false,
      hasNext: false,
      firstCursor: '',
      lastCursor: '',
      searchValue: ''
    },
    listLoading: true,
    searchValue: ''
  };

  // initial load
  componentWillMount() {
    // get the agents 
    this.getSfyCustomers();
  }

  // handle the search change
  handleSearchChange = (searchValue) => {
    // update state
    this.setState({
      listLoading: true,
      searchValue: searchValue
    });

    // check if there was no extra change within a second, otherwise wait, to prevent that several calls will be fired
    setTimeout((value) => {
      // only when there was no change in during the waiting, execute..
      if (value === this.state.searchValue) {
        this.getSfyCustomers();
      }
    }, 500, searchValue);
  };

  // handle the search change
  handleListNavigation = (direction='', cursor='') => {
    // update state (loading state)
    this.setState({
      listLoading: true,
    });

    // execute
    this.getSfyCustomers(direction,cursor);
  };

  // get the agents
  getSfyCustomers(navDirection='', cursor='') {
    const token = Token.getAuthToken();

    // get search
    let searchString = '';

    // append search
    if (this.state.searchValue) {
      searchString = this.state.searchValue;
    }

    // append the tag filter if needed
    if (this.state.agentsOnly === true) {
      searchString += ` tag:${Shopify.agentTag}`;
    }

    // console.log(this.state.selectedTab); 
    if (!token) {
      this.setState({
        error: true
      });
      return;
    }

    // sign in into firebase
    firebase.auth().signInWithCustomToken(token)
      .then(() => {
        // determine if there was a previous/next change
        // only when there is a previous click use 'last' in combination with before, otherwise always first in combination with after
        const deltaLabel = navDirection === 'previous' ? 'last' : 'first';
        const deltaDirection = navDirection === 'previous' ? 'before' : 'after';
        
        // set 'before:xx' or 'after:xx' if there is a cursor found
        let beforeAfter = '';
        if (cursor) {
          beforeAfter = `${deltaDirection}: "${cursor}",`;
        }

        const query = `{
          customers(
            ${deltaLabel}: 20, 
            ${beforeAfter}
            query: "${searchString}"
          ) {
            edges {
              cursor
              node {
                legacyResourceId
                firstName
                lastName
                email
                tags
              }
            }
            pageInfo {
              hasPreviousPage
              hasNextPage
            }
          }
        }`;

        // Execute GraphQL Query
        return ShopifyApiClient.query(query);
      })
      .then((results) => {
          const { edges, pageInfo } = results.data.customers;
          const customersFound = edges.length > 0 ? true : false;
          
          // set first and last cursor for navigation purposes
          const firstCursor = customersFound ? edges[0].cursor : null;
          const lastCursor = customersFound ? edges[edges.length-1].cursor : null;

          this.setState({...this.state,
            loading: false,
            listLoading: false,
            listConfig: {
              hasPrevious: pageInfo.hasPreviousPage,
              hasNext: pageInfo.hasNextPage,
              firstCursor: firstCursor,
              lastCursor: lastCursor,
              data: edges
            }
          });
      })
      .catch(function(error) {
        // drop an error to the client
        this.props.showToast(`Unable to load the customer list. Please reload the page and try again`, true);

        console.log("Error getting documents: ", error);
      });
  }
  
  render() {    
    return (
      <Layout>
        <Layout.Section>
        <Card title="Manage agents" sectioned>
          <p>
            You can create an agent by assigning the agent role to a customer.
          </p>
        </Card>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <Card.Section>
              <AgentList 
                resourceNameSingular="customer" 
                resourceNamePlural="customers" 
                config={this.state.listConfig}
                searchValue={this.state.searchValue}
                loading={this.state.listLoading}
                navigate={this.props.navigate.bind(this)} 
                listNavigation={this.handleListNavigation.bind(this)}
                handleSearchChange={this.handleSearchChange.bind(this)} 
                />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    );
  }
};

export default ManageAgents;