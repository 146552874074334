import React from 'react';
import { Card, Spinner, TextStyle } from '@shopify/polaris';

export const Loading = (props) => {
  return (
    <Card sectioned={true} title={props.title}>
        <div className="center loading">
          <Spinner size="large" color="inkLightest" /><br /><br />
          <TextStyle variation="subdued">Loading data...</TextStyle>
        </div>
    </Card>
  );
}
