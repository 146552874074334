import React from 'react';
import { Layout } from '@shopify/polaris';

import MultiPass from './MultiPass';
import Installation from './Installation';
import Configuration from './Configuration';

export const Settings = (props) => {
  return (
    <Layout>
      <MultiPass showToast={props.showToast.bind(this)} updateSetupRequired={props.updateSetupRequired.bind(this)} />
      <Installation showToast={props.showToast.bind(this)} updateSetupRequired={props.updateSetupRequired.bind(this)} />
      <Configuration showToast={props.showToast.bind(this)} updateSetupRequired={props.updateSetupRequired.bind(this)} />
    </Layout>
  );
};
