import React, { Component } from 'react';
import { Avatar, Badge, Card, ResourceList, Pagination, TextStyle } from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import firebase from "../../config/Firebase";
import Token from '../../common/Token';
import { Shopify } from '../../config/Shopify';
import ShopifyActions from '../../actions/ShopifyActions';

import './CustomerList.css';

import ShopifyApiClient from '../../common/ShopifyApiClient';

class CustomerList extends Component {
  static contextTypes = {
    polaris: PropTypes.object
  };

  state = {
    selectedTab: 0,
    listLoading: true,
    listDirection: null,
    listCursor: null,
    agentTag: '',
    listConfig: {
      data: [],
      hasPrevious: false,
      hasNext: false,
      firstCursor: '',
      lastCursor: ''
    },
    searchValue: ''
  }

  constructor(props) {
    super(props);

    // set agent tag
    this.state.agentTag = Shopify.customerTag.replace('$X',this.props.agent.legacyResourceId);
  }

  // initial load
  componentWillMount() {
    // get the agents 
    this.getSfyCustomers();
  }

  // handle the search change
  handleSearchChange = (searchValue) => {
    // update state
    this.setState({
      listLoading: true,
      searchValue: searchValue
    });

    // check if there was no extra change within a second, otherwise wait, to prevent that several calls will be fired
    setTimeout((value) => {
      // only when there was no change in during the waiting, execute..
      if (value === this.state.searchValue) {
        this.getSfyCustomers();
      }
    }, 500, searchValue);
  };

  // get the agents
  getSfyCustomers() {
    const token = Token.getAuthToken();

    // get search
    let searchString = '';

    // append search
    if (this.state.searchValue) {
      searchString = this.state.searchValue;
    }

    // do not add the customer record of the agent
    searchString += ` NOT tag:${Shopify.agentTag}`;
  
    // append the tag filter if needed
    if (this.props.assignedCustomersOnly === true) {
      searchString += ` tag:${this.state.agentTag}`;
    }

    if (!token) {
      this.setState({
        error: true
      });
      return;
    }

    // sign in into firebase
    firebase.auth().signInWithCustomToken(token)
      .then(() => {
        // determine if there was a previous/next change
        // only when there is a previous click use 'last' in combination with before, otherwise always first in combination with after
        const deltaLabel = this.state.listDirection === 'previous' ? 'last' : 'first';
        const deltaDirection = this.state.listDirection === 'previous' ? 'before' : 'after';
        
        // set 'before:xx' or 'after:xx' if there is a cursor found
        let beforeAfter = '';
        if (this.state.listCursor) {
          beforeAfter = `${deltaDirection}: "${this.state.listCursor}",`;
        }

        const query = `{
          customers(
            ${deltaLabel}: 20, 
            ${beforeAfter}
            query: "${searchString}"
          ) {
            edges {
              cursor
              node {
                legacyResourceId
                firstName
                lastName
                email
                tags
              }
            }
            pageInfo {
              hasPreviousPage
              hasNextPage
            }
          }
        }`;

        // Execute GraphQL Query
        return ShopifyApiClient.query(query);
      })
      .then((results) => {
          const { edges, pageInfo } = results.data.customers;
          const customersFound = edges.length > 0 ? true : false;
          
          // set first and last cursor for navigation purposes
          const firstCursor = customersFound ? edges[0].cursor : null;
          const lastCursor = customersFound ? edges[edges.length-1].cursor : null;

          this.setState({...this.state,
            loading: false,
            listLoading: false,
            listConfig: {
              hasPrevious: pageInfo.hasPreviousPage,
              hasNext: pageInfo.hasNextPage,
              firstCursor: firstCursor,
              lastCursor: lastCursor,
              data: edges
            }
          });
      })
      .catch(function(error) {
          // drop an error to the client
          this.props.showToast(`Unable to load the customer list. Please reload the page and try again`, true);

          console.log("Error getting documents: ", error);
      });
  }

  assignCustomerToAgent = (customerId) => {
    // set loading state
    this.setState({...this.state,
      listLoading: true,
    });

    ShopifyActions.assignCustomerToAgent(this.props.agent.legacyResourceId, customerId)
      .then(() => {
        // show message
        this.props.showToast(`Customer is assigned to the agent.`);

        return this.getSfyCustomers();
      })
      .catch((error) => {
        // this.props.showToast(`Something went wrong while performing an agent assign`, true);
        console.log(error.message);

          // set loading state
          this.setState({...this.state,
            listLoading: false,
          });
      });
  }

  revokeCustomerFromAgent = (customerId) => {
      // set loading state
      this.setState({...this.state,
        listLoading: true,
      });

    ShopifyActions.revokeCustomerFromAgent(this.props.agent.legacyResourceId, customerId)
      .then(() => {
        // set loading state
        this.setState({...this.state,
          listLoading: true,
        });

        // show message
        this.props.showToast(`Customer is unassigned from this agent`);

        return this.getSfyCustomers();
      })
      .catch((error) => {
        this.props.showToast(`Something went wrong while performing an agent assign`, true);
        console.log(error.message);

        // set loading state
        this.setState({...this.state,
          listLoading: false,
        });
      });
  }

  render() {
    // set action
    let actionLabel = 'Manage customers';
    let actionUrl = `/agent/${this.props.agent.legacyResourceId}/manage-customers`;
    if (this.props.assignedCustomersOnly === false) {
      actionLabel = `« Back to Agent`;
      actionUrl = `/agent/${this.props.agent.legacyResourceId}/detail`;
    }

    return (
      <Card>
        <Card.Header  
          title="Manage customers"
          actions={[
            {
              content: actionLabel,
              onClick: () => { this.props.navigate(actionUrl) }
            },
          ]}>
        </Card.Header>
        <Card.Section>
          <ResourceList
            resourceName={{singular: 'customer', plural: 'customers'}}
            items={this.state.listConfig.data}
            loading={this.state.listLoading}
            renderItem={(item) => {
              const {legacyResourceId, email, firstName, lastName, tags} = item.node;
              const media = <Avatar customer size="medium" name={firstName + ' ' + lastName} />;

              let assignedTag = '';

              let shortcutActions = [{content: 'Add', onClick: () => { this.assignCustomerToAgent(legacyResourceId); } }];
              if (tags.includes(this.state.agentTag)) {
                if (this.props.assignedCustomersOnly === false) {
                  assignedTag = <Badge status="success">Added</Badge>;
                }
                shortcutActions = [{content: 'Remove', onClick: () => { this.revokeCustomerFromAgent(legacyResourceId); } }];
              }       
              
              return (
                <ResourceList.Item
                id={legacyResourceId}
                media={media}
                accessibilityLabel={`View customer overview for ${firstName} ${lastName}`}
                shortcutActions={shortcutActions}
              >
                <div className="customer">
                  <TextStyle variation="strong">{firstName} {lastName}</TextStyle><br />
                  <TextStyle variation="subdued">{email}</TextStyle>
                </div>
                <div className="customerAssigned">
                  {assignedTag}
                </div>
              </ResourceList.Item>
              );
            }
          }
          filterControl={
            <ResourceList.FilterControl
              // filters={availableFilters}
              // appliedFilters={appliedFilters}
              // onFiltersChange={this.handleFiltersChange}
              searchValue={this.state.searchValue}
              onSearchChange={this.handleSearchChange}
              // additionalAction={{
              //   content: 'Save',
              //   // onAction: this.handleSaveFilters,
              // }}
            />
          }
          />
        <center>
          {this.renderListNavigation()}
        </center>
        </Card.Section>
      </Card>
    );
  }

  // handle the search change
  handleListNavigation = (direction='', cursor='') => {
    // update state (loading state)
    this.setState({
      listLoading: true,
      listDirection: direction,
      listCursor: cursor
    });

    // execute
    this.getSfyCustomers(direction,cursor);
  };

  renderListNavigation() {
    // guard: do not render when not needed
    if (this.state.listConfig.hasPrevious === false && this.state.listConfig.hasNext === false) {
      return '';
    }

    return (
      <Pagination
      hasPrevious={this.state.listConfig.hasPrevious}
      onPrevious={() => {
        this.handleListNavigation('previous', this.state.listConfig.firstCursor);
      }}
      hasNext={this.state.listConfig.hasNext}
      onNext={() => {
        this.handleListNavigation('next', this.state.listConfig.lastCursor);
      }}
      />
    );
  }
}

/*
<ListPagination 
hasPreviousPage={props.properties.hasPreviousPage}
hasNextPage={props.properties.hasNextPage}
/>
*/


export default CustomerList;