import React from 'react';
import { render } from 'react-dom';
import '@shopify/polaris/styles.css';
import {AppProvider} from '@shopify/polaris';

import App from './App';
import { Shopify } from './config/Shopify';

import './index.css';

// set shopify config when not in dev mode
const inDevMode = (process.env.NODE_ENV === 'development' ? true : false);
const apiKey = inDevMode === false ? Shopify.apiKey : null; 

// handle OAuth handshake
const urlParams = new URLSearchParams(window.location.search);
// we may assume that when the token is there that we're good. If the token does not match another error must be shown (only when not in dev mode)
if (inDevMode === false && urlParams.get('token') === null) {
  window.location.replace('/oauth' + window.location.search);
  render('<div>Redirecting...</div>');
} else {
  render(
    <AppProvider apiKey={apiKey}>
      <App />
    </AppProvider>,
    document.getElementById('root'),
  ); 
}

