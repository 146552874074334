import React from 'react';
import { ButtonGroup, Button, Card, TextContainer, TextStyle, Tooltip } from '@shopify/polaris';
import {
  DuplicateMinor
} from '@shopify/polaris-icons';
import copy from 'clipboard-copy';

import './Install.css';

export const Install = (props) => {
  const installText = props.snippetsInstalled ? 'Uninstall snippets' : 'Install snippets';

  const handleCopyToClipboard = string => {
    copy(string).then(() => {
      props.showToast(`Copied to clipboard`);
    }).catch(() => {
      props.showToast(`Could not copy the snippet, please try it manually`, true);
    });
  }

  const snippet1 = `{% include 'identity-switcher_head' %}`;
  const snippet2 = `{% include 'identity-switcher_open-customers-listing' %}`;

  // set instructions (based on install state)
  const introInstructionsInstall = 
    <TextContainer>
      <p>
        1. Please click on the button below to install and configure the needed snippets.
      </p>
      <ButtonGroup>
          <Button onClick={props.snippetsInstalled ? props.unInstallSnippets : props.installSnippets} loading={props.buttonLoading} destructive={props.snippetsInstalled}>{installText}</Button>
      </ButtonGroup>
      <p>
        Include these code snippets into your <TextStyle variation="code">theme.liquid</TextStyle> file:<br /><br />
        2. Include this snippet before the closing <TextStyle variation="code">&lt;/head&gt;</TextStyle> tag
        <span className="codeSnippet">
          <TextStyle variation="code">
              <span className="snippet">
                {snippet1}
              </span>
              <Tooltip
                content="Copy to clipboard"
                preferredPosition="above"
              >
                <Button
                  plain
                  icon={DuplicateMinor}
                  size="slim"
                  accessibilityLabel="Copy to clipboard"
                  onClick={() => handleCopyToClipboard(snippet1)}
                />
              </Tooltip>
          </TextStyle>
        </span>

        3. Include this snippet right after the opening <TextStyle variation="code">&lt;body&gt;</TextStyle> tag
        <span className="codeSnippet">
          <TextStyle variation="code">
              <span className="snippet">
                {snippet2}
              </span>
              <Tooltip
                content="Copy to clipboard"
                preferredPosition="above"
              >
                <Button
                  plain
                  icon={DuplicateMinor}
                  size="slim"
                  accessibilityLabel="Copy to clipboard"
                  onClick={() => handleCopyToClipboard(snippet2)}
                />
              </Tooltip>
          </TextStyle>
        </span>
      </p>
      </TextContainer>
    ;

  const introUninstructionsInstall = 
  <TextContainer>
    <p>
      Please click on the button below to uninstall the snippets (note that you will have to remove the snippets from step 2 and 3)
    </p>
    <ButtonGroup>
      <Button onClick={props.snippetsInstalled ? props.unInstallSnippets : props.installSnippets} loading={props.buttonLoading} destructive={props.snippetsInstalled}>{installText}</Button>
    </ButtonGroup>
    <p>
    Include these code snippets into your <TextStyle variation="code">theme.liquid</TextStyle> file:<br /><br />
    2. Include this snippet before the closing <TextStyle variation="code">&lt;/head&gt;</TextStyle> tag
    <span className="codeSnippet">
      <TextStyle variation="code">
          <span className="snippet">
            {snippet1}
          </span>
          <Tooltip
            content="Copy to clipboard"
            preferredPosition="above"
          >
            <Button
              plain
              icon={DuplicateMinor}
              size="slim"
              accessibilityLabel="Copy to clipboard"
              onClick={() => handleCopyToClipboard(snippet1)}
            />
          </Tooltip>
      </TextStyle>
    </span>

    3. Include this snippet right after the opening <TextStyle variation="code">&lt;body&gt;</TextStyle> tag
    <span className="codeSnippet">
      <TextStyle variation="code">
          <span className="snippet">
            {snippet2}
          </span>
          <Tooltip
            content="Copy to clipboard"
            preferredPosition="above"
          >
            <Button
              plain
              icon={DuplicateMinor}
              size="slim"
              accessibilityLabel="Copy to clipboard"
              onClick={() => handleCopyToClipboard(snippet2)}
            />
          </Tooltip>
      </TextStyle>
    </span>
  </p>
  </TextContainer>;

  return(
    <Card>
      <Card.Header title="Liquid snippets">
      </Card.Header>
      <Card.Section>
        {props.snippetsInstalled ? introUninstructionsInstall : introInstructionsInstall}
      </Card.Section>
    </Card>
  );
};

/*  onClick={props.toggleForm} */