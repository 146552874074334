import React from 'react';
import { Layout, Spinner, TextStyle } from '@shopify/polaris';

export const Loading = (props) => {
  return (
    <Layout>
      <Layout.Section>
        <div className="center loading">
          <Spinner size="large" color="inkLightest" /> <br /><br />
          <TextStyle variation="subdued">{props.description}...</TextStyle>
        </div>
      </Layout.Section>
    </Layout>
  );
};
