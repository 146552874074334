import React, {Component} from 'react';
import { Card, Layout } from '@shopify/polaris';
import * as PropTypes from 'prop-types';

import { AssignAgent } from './detail/AssignAgent';
import CustomerList from './detail/CustomerList';

import { Loading } from './Loading';

import Token from '../common/Token';
import { Shopify } from '../config/Shopify';

import ShopifyApiClient from '../common/ShopifyApiClient';
import ShopifyActions from '../actions/ShopifyActions';

class AgentDetail extends Component {
  static contextTypes = {
    polaris: PropTypes.object
  };
  
  state = {
    loading: true,
    error: false,
    customer: null,
    assignButtonLoading: false,
    activeRevokeModal: false
  };

  // initial load
  componentWillMount() {
    // get the ID of the customer (get from the params)
    const sfyCustomerId = this.props.match.params.id;

    // get the customer By ID
    // console.log(`Obtain Shopify Customer ID: ${sfyCustomerId}`);
    this.getCustomerById(sfyCustomerId);
  }

  // handle the search change
  getCustomerById(sfyCustomerId) {
    const token = Token.getAuthToken();

    if (!token) {
      this.setState({
        loading: false,
        error: true
      });
      return;
    }

    // set query
    const query = `{
      customer(id: "gid://shopify/Customer/${sfyCustomerId}") {
        legacyResourceId
        firstName
        lastName
        email
        tags
      }
    }`;
    // console.log(query);

    ShopifyApiClient.query(query)
      .then((result) => {
        const shopifyData = result.data.customer;

        // // update the state so the rendering is updated as well
        if (shopifyData) {
          // set state
          this.setState({...this.state,
            loading: false,
            customer: shopifyData,
            activeRevokeModal: false
          });

          // run the navigate option to force an update of the title (in case of a hard refresh)
          // since were already on the correct page it will only update the title
          this.props.navigate(this.props.location.pathname, `${shopifyData.firstName} ${shopifyData.lastName}`);
        }
      })
      .catch(function(error) {
          console.log("Error getting documents: ", error);
      });
  }

  toggleRevokeModal = () => {
    this.setState(({activeRevokeModal}) => ({activeRevokeModal: !activeRevokeModal}));

    // give feedback to the user
    // this.props.showToast('Customer agent state was changed');
  }

  assignAgent = (customerId) => {
    // set loading state
    this.setState({...this.state,
      assignButtonLoading: true
    });

    ShopifyActions.assignCustomerAsAgent(customerId)
      .then(() => {
        // set loading state
        this.setState({...this.state,
          loading: true,
          assignButtonLoading: false
        });

        // reload the customer
        this.getCustomerById(this.state.customer.legacyResourceId);

        // show message
        this.props.showToast(`Customer ${this.state.customer.firstName} ${this.state.customer.lastName} is an agent now. You can add customers.`);
      })
      .catch((error) => {
        this.props.showToast(`Something went wrong while performing an agent assign`, true);
        console.log(error.message);
      });
  }

  revokeAgent = (customerId) => {
    // set loading state
    this.setState({...this.state,
      loading: true,
      assignButtonLoading: true
    });

    ShopifyActions.revokeCustomerAsAgent(customerId)
      .then(() => {
        // set loading state
        this.setState({...this.state,
          loading: true,
          activeRevokeModal: false,
          assignButtonLoading: false
        });

        // reload the customer
        this.getCustomerById(this.state.customer.legacyResourceId);

        // show message
        this.props.showToast(`Agent rights for ${this.state.customer.firstName} ${this.state.customer.lastName} has been revoked.`);
      })
      .catch((error) => {
        // set loading state
        this.setState({...this.state,
          loading: false,
          activeRevokeModal: false,
          assignButtonLoading: false
        });

        this.props.showToast(`Something went wrong while performing an agent revoke`, true);
        console.log(error.message);
      });
  }

  render() {
    // loading guard
    if (this.state.loading === true) {
      return (
        <Loading />
      );
    } else {
      // check if this customer is an agent
      if (this.state.customer.tags.includes(Shopify.agentTag)) {
        return (
          <Layout>
            <Layout.Section>
              <Card title={`Manage customers of agent ${this.state.customer.firstName} ${this.state.customer.lastName}`} sectioned>
                <p>
                  To enable switching to a customer's identity, please add that customer to the agent.
                </p>
              </Card>
              <CustomerList 
                navigate={this.props.navigate.bind(this)} 
                agent={this.state.customer} 
                showToast={this.props.showToast.bind(this)}
                assignedCustomersOnly={false}
                />
            </Layout.Section>
          </Layout>
        )
      } else {
        return (
          <AssignAgent 
              customer={this.state.customer}
              buttonLoading={this.state.assignButtonLoading}
              assignAsAgent={this.assignAgent.bind(this)}
          />
        );
      }
    }
  }
};

export default AgentDetail;