/**
 * Shopify App Settings
 * 
 */

export const Shopify = {
    // apiKey: null,
    apiKey: "a10b7145b33401b7d76d704c0d1c55cf",
    appAdminUrl: "/apps/identityswitcher",
    queryUrl: "https://europe-west1-code-identity-switcher.cloudfunctions.net/graphql",
    assetInstallUrl: "https://europe-west1-code-identity-switcher.cloudfunctions.net/installAssets",
    assetUninstallUrl: "https://europe-west1-code-identity-switcher.cloudfunctions.net/uninstallAssets",
    agentTag: "IDS-AGENT",
    superAgentTag: "IDS-AGENT-ALL",
    customerTag: "IDS-ASSUMABLE-$X"
};
