import React from 'react';
import { FooterHelp, Link } from '@shopify/polaris';

export const Footer = (props) => {
  return (
    <FooterHelp>
      Learn more about{' '}
      <Link url="https://apps.support.code.nl/hc/en-us/sections/360000997338-Identity-Switcher" external={true}>
        Identity Switcher
      </Link>
      .
    </FooterHelp>
  );
};
